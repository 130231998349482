import * as React from 'react'
import {observer} from 'mobx-react-lite'
import systemStore from '../../storage/SystemStore'
import './Loading.css'

const Loading = () => {
  if (!systemStore.loading) return null
  return (
    <div className={'loading'}>
      <div className={'loading_content'}>
          <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Жүктөлүүдө...</span>
          </div>
      </div>
    </div>
  )
}
export default observer(Loading)