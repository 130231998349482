import React, {useEffect} from 'react';
import './Header.css'
import systemStore from "../../storage/SystemStore"
import {observer} from "mobx-react-lite";
import RightSidebar from './RightSidebar'
import {Link} from "react-router";
import appStore from "../../storage/AppStore";
import {useTelegram} from "../../hooks/useTelegram";
import logo from "../../images/small_logo.png"
const Header = () => {
    const {isValid,tg} = useTelegram();
  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', systemStore.mode)
  }, [systemStore.mode])

  return (
      <>
        <header className="navbar navbar-expand-lg bg-body navbar-sticky sticky-top z-fixed px-0 is-stuck"
                data-sticky-element>
          <div className="container flex-nowrap">
            {/* Mobile offcanvas menu toggler (Hamburger) */}
            <button type="button" className="navbar-toggler me-4 me-lg-0" data-bs-toggle="offcanvas"
                    data-bs-target="#navbarNav" aria-controls="navbarNav" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"/>
            </button>
            {/* Navbar brand (Logo) */}
            <Link className="navbar-brand py-1 py-md-2 py-xl-1" to="/">
        <span className="d-sm-flex flex-shrink-0 text-primary me-2">
          <img src={logo} alt="Sooda Online" height="64" width="64" className="logo-img"/>
        </span>
              Sooda
            </Link>
            <RightSidebar/>
            {/* Button group */}
            <div className="d-flex align-items-center">
                {!isValid() && <a href="https://t.me/soodaonline_bot" className="btn btn-icon btn-lg btn-outline-secondary fs-lg border-0 rounded-circle animate-shake"><i className="ci-telegram animate-target"/></a>}
              <button
                  onClick={() => {systemStore.toggleMode(systemStore.mode === 'light'? 'dark' : 'light')}}
                  type="button"
                  className="btn btn-icon btn-lg fs-xl btn-outline-secondary border-0 rounded-circle animate-rotate">
                {systemStore.mode === 'light' ? <i className="ci-moon animate-target"/> : <i className="ci-sun animate-target"/>}
              </button>
              {/* Lang switcher (kg/ru) */}
              <div className="dropdown">
                <button type="button"
                        className="theme-switcher btn btn-icon btn-lg btn-outline-secondary fs-lg border-0 rounded-circle animate-scale"
                        data-bs-toggle="dropdown" aria-expanded="false" aria-label="Toggle theme (light)">
                    <span className="theme-icon-active d-flex animate-target">
                      {appStore.user.language === 'kg' ? <>🇰🇬</> : <>🇷🇺</> }
                    </span>
                </button>
                <ul className="dropdown-menu" style={{"--cz-dropdown-minWidth": "9rem"}}>
                  <li>
                    <button type="button" className={appStore.user.language === 'kg' ? "dropdown-item active" : "dropdown-item"} data-bs-theme-value="light"
                            aria-pressed="true" onClick={() => {if(appStore.user.language !=='kg') {appStore.setLanguage('kg')}}}>
                        <span className="theme-icon d-flex fs-base me-2">
                          🇰🇬
                        </span>
                          <span className="theme-label">Кыргызча</span>
                          <i className="item-active-indicator ci-check ms-auto"/>
                    </button>
                  </li>
                  <li>
                    <button type="button" className={appStore.user.language === 'ru' ? "dropdown-item active" : "dropdown-item"} data-bs-theme-value="dark"
                            aria-pressed="false"  onClick={() => {if(appStore.user.language !=='ru') {appStore.setLanguage('ru')}}}>
                        <span className="theme-icon d-flex fs-base me-2">
                          🇷🇺
                        </span>
                          <span className="theme-label">Русский</span>
                          <i className="item-active-indicator ci-check ms-auto"/>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
          {!isValid() &&
              <div className="alert alert-info alert-dismissible fade show" role="alert">

                  <div><a className="alert-link" href="https://t.me/soodaonline_bot">{appStore.tr.go_to_app}</a>
                  </div>
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
          }
      </>
  )
}
export default observer(Header);